<template>
    <li>
        <item-card>
            <template v-slot:title>
                <h3 class="text-lg font-medium text-gray-900 dark:text-gray-300">
                    {{item.description}} <span class="font-base text-base"> - {{item.code}}</span>
                </h3>
            </template>

            <template v-slot:content>

                <item-text title="Cena" :text="item.price" />
                <item-text title="Daudzums" :text="item.quantity" />
                <item-text title="Kopā" :text="item.total" textClass="font-bold" />
                <item-text title="PVN" :text="item.vat"  />
                <item-text title="Kopā ar PVN" :text="item.total_with_vat" textClass="font-bold"  />

            </template>

        </item-card>
    </li>
</template>

<script>

import ItemCard from "../Components/ItemCard";
import ItemText from "../Components/ItemText";

export default {
    name: "SingleCreditNoteItem",
    components : {
        ItemCard,
        ItemText,
    },
    props: ['creditNote','item'],
    data: () => ({
    }),
    methods: {

    }
}
</script>

<style scoped>

</style>