<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Pozīcijas
            </h3>
        </template>

        <template v-slot:content>

            <!--  List all order items  -->
            <template v-if="creditNote.credit_note_items">
                <div class="divide-y divide-gray-200 dark:divide-gray-700 w-full">
                    <SingleCreditNoteItem v-for="(item, index) in creditNote.credit_note_items" :key="index"
                                          :item="item" :order="creditNote"/>
                </div>
            </template>

        </template>
    </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import SingleCreditNoteItem from "./SingleCreditNoteItem";

export default {
    name: "AllCreditNoteItems",
    props: ['creditNote'],
    components: {
        SingleCreditNoteItem,
        ItemCard,
    },
}
</script>
